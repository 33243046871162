import './bootstrap';
import '../css/tw.css';
import '../css/app.css';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { createApp, h } from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from 'primevue/tooltip';
import AppLayout from "@/Layouts/AppLayout.vue";
import BadgeDirective from "primevue/badgedirective";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

Bugsnag.start({
    apiKey: 'e4d1f059696627eec055b65617b3d211',
    plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({ apiKey: 'e4d1f059696627eec055b65617b3d211' })

const bugsnagVue = Bugsnag.getPlugin('vue')

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    // resolve: name => {
    //     const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
    //     let page = pages[`./Pages/${name}.vue`]
    //     page.default.layout = page.default.layout || AppLayout
    //     return page
    // },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(PrimeVue)
            .use(ToastService)
            .use(ConfirmationService)
            .use(bugsnagVue)
            .directive('tooltip', Tooltip)
            .directive('badge', BadgeDirective)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
